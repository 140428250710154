import React from 'react';

const AboutUsSection = () => {
  return (
    <div className="bg-white py-16 px-8 max-w-2xl mx-auto">
      <div className="text-center max-w-4xl mx-auto">
        {/* About Us Heading */}
        <h3 className="text-gray-600 text-xl font-semibold mb-4">About us</h3>

        {/* Main Heading */}
        <h2 className="text-black text-xl font-bold mb-6">
          <span className="text-blue-600">Collaborate.us</span> is your comprehensive gateway to a safer and more transparent freight industry
        </h2>

        {/* Description Paragraph */}
        <p className="text-gray-600 text-base mb-12 text-sm">
        Collaborative Rating Systems LLC was founded by an industry veteran with over 33 years of experience in the freight industry.

With freight broker, owner-operator, driver, and freight forwarder experience and a deep expertise in FMCSA regulations, CollaboRate brings together the most comprehensive and well-thought-out approach to combating freight fraud.

Our suite of advanced platforms is designed to enhance the integrity of freight transactions, ensuring compliance and building trust across the industry.

Each of our platforms can be used independently or in combination, giving you the flexibility to address fraud on your terms.

Whether you’re a Shipper, Motor Carrier, Freight Broker, Freight Forwarders or industry Stakeholder that supports them, you can leverage the tools that best meet your needs, or integrate them for a powerful, all-encompassing defense against fraud.

Our mission is simple:

We intend to create a secure environment where every stakeholder can operate with confidence, knowing they’re backed by the most robust and adaptable fraud prevention solutions in the freight industry.

        </p>

        {/* Vision Section */}
        <h3 className="text-black text-xl mb-4">Our Vision</h3>

        <p className="text-gray-600 text-base text-sm">
          We envision a freight industry free from fraud, where every transaction is secure, every player is verified, and every load is protected. Through collaboration and cutting-edge technology, we aim to set new standards in fraud prevention and compliance.
        </p>
      </div>
    </div>
  );
};

export default AboutUsSection;
